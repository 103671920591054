<template>
    <div class="box  overflow-hidden flex flex-col relative" :class="billing ? 'h-1/2 mb-2' : 'w-2/5 mr-1'">
        <loader :loading="loading" />
        <div class="box-title mb-4">Billing</div>
        <div class="h-full overflow-hidden flex flex-col justify-around">
            <div class="flex justify-between items-center mb-1">
                <div class="text-lg font-semibold text-font-dark">Invoiced</div>
                <div class="text-xl font-bold">{{ billing_data.invoiced | reduceBigNumbers(2) }} €</div>
            </div>
            <div class="flex justify-between items-center ">
                <div class="text-font-dark">Collected</div>
                <div class="text-lg text-font-light">{{ billing_data.collected | reduceBigNumbers(2) }} €</div>
            </div>
            <div class="flex justify-between items-center mb-6">
                <div class="text-font-dark">Not Collected</div>
                <div class="text-lg text-font-light">{{ billing_data.not_collected | reduceBigNumbers(2) }} €</div>
            </div>
            <div class="flex justify-between items-center">
                <div class="text-lg font-semibold text-font-dark">Unbilled</div>
                <div class="text-xl font-bold">{{ billing_data.unbilled | reduceBigNumbers(2) }} €</div>
            </div>
        </div>
    </div>

</template>

<script>

import { state } from '@/store';

export default {
    props: ['billing'],
    data() {
        return {
            loading: false,
            billing_data: false
        }
    },
    methods:{
        load() {
            this.loading = true

            // if(state.rolSelected.role_type_link != 'presidencia'){
             
                this.axios.get('billing/billing', {params: this.params}).then(response => {
                    this.billing_data = response.data.data;
                })

            // }

            this.loading = false;

        }
    },
    computed: {
        params() {
            let params = state.globalParams
            if (this.$route.params.catId) {
                params = {...params, ...{sectorAmbiciona: this.$route.params.catId} }
            }
            return params
        }
    },
    watch: {
        params() { this.load() }
    },
    mounted() { this.load() }
}

</script>